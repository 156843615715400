import React, { useReducer } from 'react';
import Login from './components/login/login'
import NewPassword from './components/login/newpassword'
import SignInError from './components/login/error'
import ForgotPassword from './components/login/forgotpassword'
import ConfirmPassword from './components/login/confirmpassword'
import ChangePassword from './components/login/changepassword'
import SetUpMFA from './components/user/setup-mfa'
import VerifyMFA from './components/login/verify-mfa'
import DashBoard from './components/dashboard/dashboard'
import './App.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import { BrowserRouter, Router, Route, Switch } from 'react-router-dom';
import UserContextProvider from "./components/user/usercontextprovider"
import UserContext, { userContextReducer } from "./components/user/usercontext"
const initialUser = {}
function App() {
  const [userState, update] = useReducer(userContextReducer, initialUser)
  let user = { Message: userState, SignIn: update, SignOut: update }

  return (
    <UserContextProvider value={user}>
      <BrowserRouter>
        <Switch>
          <Route exact path="/">
            <Login />
          </Route>
          <Route path="/bnn/auth" render={props => <Login {...props} />} />
          <Route path="/newpassword" render={props => <NewPassword {...props} />} />
          <Route path="/dashboard" render={props => <DashBoard {...props} />} />
          <Route path="/forgotpassword" render={props => <ForgotPassword {...props} />} />
          <Route path="/confirmpassword" render={props => <ConfirmPassword {...props} />} />
          <Route path="/changepassword" render={props => <ChangePassword {...props} />} />
          <Route path="/setupmfa" render={props => <SetUpMFA {...props} />} />
          <Route path="/verifymfa" render={props => <VerifyMFA {...props} />} />
          <Route path="/signinerror" render={props => <SignInError {...props} />} />
        </Switch>
      </BrowserRouter>
    </UserContextProvider>
  );
}

export default App;
