import React, { useState, useRef, useContext } from "react";
import UserContext, { } from './../user/usercontext';
import Form from "react-bootstrap/Form";
import Button from "react-bootstrap/Button";
import Alert from "react-bootstrap/Alert";
import Row from "react-bootstrap/Row"
import Col from "react-bootstrap/Col"
import "./Login.css";
import Container from 'react-bootstrap/Container';
import Card from "react-bootstrap/Card"
import { Redirect } from 'react-router-dom';
import logo from "./../../logo.png"

export default function VerifyMFA(props) {
    const { Message, SignIn, SignOut } = useContext(UserContext)
    console.log("from Message", Message);
    const [user, setUser] = useState<any>(props.location.data && props.location.data.user ? props.location.data.user : {})
    const [orgName, setOrgName] = useState(props.location.data && props.location.data.orgName ? props.location.data.orgName : "")
    const [isLoggedIn, setIsLoggedIn] = useState(props.location.data && props.location.data.user ? true : false);
    const [signIn, setSignIn] = useState(false)
    const [error, setError] = useState("")
    const [code, setCode] = useState("")
    const form: any = useRef(null)
    function validateForm() {
        return code.length > 0;
    }

    function handleSubmit(event) {
        event.preventDefault();
        let apiUrl = "/api/v1/banyanidp/user/verifymfa"
        if (Message.Message.ChallengeName === "SOFTWARE_TOKEN_MFA") {
            apiUrl = apiUrl + "?mode=otp"
        }
        console.log("api url", apiUrl);
        let data: any = {
            "username": user.UserName,
            "code": code,
            "orgName": orgName,
            "session": user.Session
        }

        fetch(apiUrl, {
            method: 'POST', headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(data)
        })
            .then(res => res.json())
            .then(json => {
                if (json.data && json.data.Message) {
                    setUser(json.data)
                    SignIn({ type: "SIGN_IN", user: json.data })
                    setSignIn(true)
                } else {
                    setError(json.error_description)
                }
            })
    }

    const styles = {
        card: {
            borderRadius: 10,
        },
        cardHeader: {
            backgroundColor: "#031828",
        },
        cardImage: {
            height: '300px',
            width: '300px',
            align: 'center'
        }
    }

    if (!isLoggedIn) {
        return <Redirect to={{
            pathname: '/'
        }} />
    }

    if (signIn) {
        return <Redirect to={{
            pathname: '/dashboard',
            data: { user: user, orgName: orgName }

        }} />
    }

    return (
        <Container>
            <Row className="justify-content-center">
                <Col sm="6">
                    <Card style={styles.card} className="text-center">
                        <Card.Header style={styles.cardHeader}>
                            <Card.Img variant="top" src={logo} style={styles.cardImage} />
                        </Card.Header>

                        <Card.Body>
                            <Row>
                                <Col sm="12">
                                    <Form onSubmit={handleSubmit} ref={form} name="loginform">
                                        <Form.Group as={Row}>
                                            <Form.Label column sm="4">Code</Form.Label>
                                            <Col sm="8">
                                                <Form.Control
                                                    type="text"
                                                    name="code"
                                                    id="code"
                                                    value={code}
                                                    onChange={(e) => setCode(e.target.value)}
                                                    placeholder="Enter Security Code"

                                                /></Col>
                                        </Form.Group>
                                        <Button block size="lg" type="submit" disabled={!validateForm()}>
                                            Verify
                                        </Button>
                                    </Form>
                                </Col>
                            </Row>
                            <Row>
                                <Col>
                                    {error.length == 0
                                        ? <br />
                                        : <Alert variant="danger">Oops! Try again</Alert>
                                    }
                                </Col>
                            </Row>
                        </Card.Body>
                    </Card>
                </Col>
            </Row>
        </Container >
    );
}