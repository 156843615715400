import React, { createContext, useReducer } from 'react'
import UserContext, { userContextReducer } from "./usercontext"
const initialUser = {}
export default function UserContextProvider(props) {
    const [userState, update] = useReducer(userContextReducer, initialUser)
    let user = { Message: userState, SignIn: update, SignOut: update }
    return (
        <UserContext.Provider value={user}>
            {props.children}
        </UserContext.Provider>
    )
}

