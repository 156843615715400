import React, { useContext, Dispatch } from 'react';


export const inistialUserState: any = {
    User: {}
};

export const UserMessage = {
    Message: inistialUserState,
    SignIn: (input: any) => { },
    SignOut: () => { },
}

export interface IUserMessage {
    Message: any,
    SignIn: React.Dispatch<any>;
    SignOut: React.Dispatch<any>;
}



const UserContext = React.createContext<IUserMessage>(UserMessage);

export const useUserContext = () => useContext(UserContext)

export default UserContext;

export const userContextReducer = (state: any, action: any) => {
    console.log("userContextReducer action", action)
    switch (action.type) {
        case 'SIGN_IN':
            let newState = {
                ...state,
                Message: action.user.Message
            };
            console.log("Signed in User State ", newState)
            return newState
        case 'SIGN_OUT':
            newState = {
                ...state,
                Message: {}
            };
            console.log("Signed out User State ", newState)
            return newState
        default:
            return state;
    }
}

export const useUserState = (defaultValue, orgName) => {
    const key = orgName + "_user";
    const [value, setValue] = React.useState(() => {
        const user = window.localStorage.getItem(key);
        return user !== null
            ? JSON.parse(user)
            : defaultValue;
    });
    React.useEffect(() => {
        window.localStorage.setItem(key, JSON.stringify(value));
    }, [key, value]);
    return [value, setValue];
}
