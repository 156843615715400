import React, { useState, useRef } from "react";
import Alert from "react-bootstrap/Alert";
import Row from "react-bootstrap/Row"
import Col from "react-bootstrap/Col"
import "./Login.css";
import Container from 'react-bootstrap/Container';
import Card from "react-bootstrap/Card"
import { Redirect } from 'react-router-dom';
import logo from "./../../logo.png"

export default function SignInError(props) {
    console.log("from login", props);
    const [user, setUser] = useState<any>(props.location.data && props.location.data.user ? props.location.data.user : {})
    const [orgName, setOrgName] = useState(props.location.data && props.location.data.orgName ? props.location.data.orgName : "")
    const [state, setState] = useState(props.location.data && props.location.data.state ? props.location.data.state : "")
    const styles = {
        card: {
            borderRadius: 10,
        },
        cardHeader: {
            backgroundColor: "#031828",
        },
        cardImage: {
            height: '300px',
            width: '300px',
            align: 'center'
        }
    }

    return (
        <Container>
            <Row className="justify-content-center">
                <Col sm="6">
                    <Card style={styles.card} className="text-center">
                        <Card.Header style={styles.cardHeader}>
                            <Card.Img variant="top" src={logo} style={styles.cardImage} />
                        </Card.Header>

                        <Card.Body>
                            <Row>
                                <Col sm="12">
                                    <Alert variant="danger">
                                        <Alert.Heading>Oh snap! You got an error!</Alert.Heading>
                                        <p>
                                            Unauthorized, Please contact your org's administrator
                                        </p>
                                    </Alert>
                                </Col>
                            </Row>
                        </Card.Body>
                    </Card>
                </Col>
            </Row>
        </Container >
    );
}