import React, { useState, useRef, useContext, useEffect } from "react";
import Form from "react-bootstrap/Form";
import Button from "react-bootstrap/Button";
import Row from "react-bootstrap/Row"
import Col from "react-bootstrap/Col"
import UserContext, { } from './../user/usercontext';
import Container from 'react-bootstrap/Container';
import Card from "react-bootstrap/Card";
import QRCode from "qrcode.react";

import logo from "./../../logo.png"

export default function SetupMFA(props) {
    const { Message, SignIn, SignOut } = useContext(UserContext)
    console.log("User from context setup mfa", Message)
    const [secretCode, setSecretCode] = useState("")
    const [code, setCode] = useState("")
    const [qrCode, setQRCode] = useState("")
    const [authenticator, setAuthenticator] = useState(false)
    const [sma, setSMS] = useState(false)
    const form: any = useRef(null)
    function validateForm() {
        return secretCode.length > 0;
    }
    useEffect(() => {
        //handleAssociate()
    }, []);

    function parseJwt(token) {
        var base64Payload = token.split('.')[1];
        var payload = Buffer.from(base64Payload, 'base64');
        return JSON.parse(payload.toString());
    }

    function handleSubmit(event) {
        event.preventDefault();
        let apiUrl = "/api/v1/cognito/user/verifyotp"
        let data: any = {
            "accessToken": Message.Message.AuthenticationResult.AccessToken,
            "code": code,
            "deviceName": "Web"
        }

        fetch(apiUrl, {
            method: 'POST', headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(data)
        })
            .then(res => res.json())
            .then(json => {
                if (json.data && json.data.Message) {
                    console.log("json from verify mfa", json)
                }
            })
    }
    function handleAssociate() {
        let apiUrl = "/api/v1/cognito/user/associateotp"
        let data: any = {
            "accessToken": Message.Message.AuthenticationResult.AccessToken
        }

        fetch(apiUrl, {
            method: 'POST', headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(data)
        })
            .then(res => res.json())
            .then(json => {
                if (json.data && json.data.Message) {
                    console.log("json from associate mfa", json)
                    setSecretCode(json.data.Message.SecretCode)
                    let token = parseJwt(Message.Message.AuthenticationResult.AccessToken);
                    const qrCodeUrl = "otpauth://totp/AWSCognito:" + token.username + "?secret=" + json.data.Message.SecretCode + "&issuer=Banyan Dev (" + token.username + ")";
                    setQRCode(qrCodeUrl)
                    console.log(qrCodeUrl)
                }
            })
    }

    const styles = {
        card: {
            borderRadius: 10,
        },
        cardHeader: {
            backgroundColor: "#031828",
        },
        cardImage: {
            height: '300px',
            width: '300px',
            align: 'center'
        }
    }



    return (
        <Container>
            <Row className="justify-content-center">
                <Col sm="6">
                    <Card style={styles.card} className="text-center">
                        <Card.Header style={styles.cardHeader}>
                            <Card.Img variant="top" src={logo} style={styles.cardImage} />
                        </Card.Header>

                        <Card.Body>
                            <Row>
                                <Col>
                                    <Button onClick={(e) => setSMS(true)} >SMS</Button>
                                    <Button onClick={(e) => setAuthenticator(true)} >Authenticator</Button>
                                </Col>
                            </Row>

                            <Row>
                                <Col>
                                    <QRCode value={qrCode} />
                                </Col>
                            </Row>

                            <Row>
                                <Col sm="12">
                                    <Form onSubmit={handleSubmit} ref={form} name="mfaForm">
                                        <Form.Group as={Row}>
                                            <Form.Label column sm="4">Code</Form.Label>
                                            <Col sm="8">
                                                <Form.Control
                                                    type="text"
                                                    name="code"
                                                    id="code"
                                                    value={code}
                                                    onChange={(e) => setCode(e.target.value)}
                                                    placeholder="Enter Security Code"

                                                /></Col>
                                        </Form.Group>
                                        <Button block size="lg" type="submit" disabled={!validateForm()}>
                                            Verify
        </Button>
                                    </Form>
                                </Col>
                            </Row>
                        </Card.Body>
                    </Card>
                </Col>
            </Row>
        </Container >
    );
}