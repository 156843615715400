import React, { useState, useRef } from "react";
import Form from "react-bootstrap/Form";
import Button from "react-bootstrap/Button";
import Row from "react-bootstrap/Row"
import Col from "react-bootstrap/Col"
import "./Login.css";
import Container from 'react-bootstrap/Container';
import Card from "react-bootstrap/Card"

import logo from "./../../logo.png"
import { Redirect } from 'react-router-dom';

export default function ForgotPassword(props) {
    console.log("from login", props);
    const [email, setEmail] = useState("");
    const [orgName, setOrgName] = useState("");
    const [confirmPassword, setConfirmPassword] = useState(false)
    const [cancel, setCancel] = useState(false)
    const form: any = useRef(null)
    function validateForm() {
        return email.length > 0 && orgName.length > 0;
    }

    function handleSubmit(event) {
        event.preventDefault();
        let apiUrl = "/api/v1/cognito/user/forgotpassword"
        console.log("user", email);
        let data: any = {
            "userName": email,
            "orgName": orgName,
        }

        fetch(apiUrl, {
            method: 'POST', headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(data)
        })
            .then(res => res.json())
            .then(json => {
                console.log("user to confirm code and new password ...", json)
                setConfirmPassword(true)
                //todo: navigate to dashboard
            })
    }

    if (confirmPassword) {
        return <Redirect to={{
            pathname: '/confirmpassword',
            data: { user: email, orgName: orgName }

        }} />
    }

    const styles = {
        card: {
            borderRadius: 10,
        },
        cardHeader: {
            backgroundColor: "#031828",
        },
        cardImage: {
            height: '300px',
            width: '300px',
            align: 'center'
        }
    }

    if (cancel) {
        return <Redirect to={{
            pathname: '/'
        }} />
    }

    return (
        <Container>
            <Row className="justify-content-center">
                <Col sm="6">
                    <Card style={styles.card} className="text-center">
                        <Card.Header style={styles.cardHeader}>
                            <Card.Img variant="top" src={logo} style={styles.cardImage} />
                        </Card.Header>

                        <Card.Body>
                            <Row>
                                <Col sm="12">
                                    <Form onSubmit={handleSubmit} ref={form} name="forgotPasswordForm">
                                        <Form.Group as={Row}>
                                            <Form.Label column sm="3">Org Name</Form.Label>
                                            <Col sm="9">
                                                <Form.Control
                                                    autoFocus
                                                    name="orgname"
                                                    id="orgname"
                                                    value={orgName}
                                                    onChange={(e) => setOrgName(e.target.value)}
                                                    placeholder="Enter Org Name"
                                                /></Col>
                                        </Form.Group>
                                        <Form.Group as={Row}>
                                            <Form.Label column sm="3">Email</Form.Label>
                                            <Col sm="9">
                                                <Form.Control
                                                    autoFocus
                                                    name="username"
                                                    id="username"
                                                    type="email"
                                                    value={email}
                                                    onChange={(e) => setEmail(e.target.value)}
                                                    placeholder="Enter email"
                                                /></Col>
                                        </Form.Group>
                                        <Button block size="lg" type="submit" disabled={!validateForm()}>
                                            Forgot Password
        </Button>
                                    </Form>
                                </Col>
                            </Row>

                            <br></br>
                            <Row>
                                <Col sm="12">
                                    <Button variant="secondary" block onClick={() => setCancel(true)}>Cancel</Button>
                                </Col>
                            </Row>

                        </Card.Body>
                    </Card>
                </Col>
            </Row>
        </Container >
    );
}