import React, { useState, useRef, useContext } from "react";
import Form from "react-bootstrap/Form";
import Button from "react-bootstrap/Button";
import Row from "react-bootstrap/Row"
import Col from "react-bootstrap/Col"
import Card from "react-bootstrap/Card"
import "./Login.css";
import Container from 'react-bootstrap/Container';
import logo from "./../../logo.png"
import { Redirect } from 'react-router-dom';
import UserContext, { } from './../user/usercontext';
import userEvent from "@testing-library/user-event";

export default function ChangePassword(props) {
    console.log(props)
    const [dashboard, setDashboard] = useState(false)
    const { Message, SignIn, SignOut } = useContext(UserContext)
    console.log("UpdateUserContext", Message)
    const [user, setUser] = useState(props.location.data.user)
    const [newPassword, setNewPassword] = useState("")
    const [oldPassword, setOldPassword] = useState("")
    const [email, setEmail] = useState(user.Message.ChallengeParameters.USER_ID_FOR_SRP);
    const [accessToken, setAccessToken] = useState(user.Message.AuthenticationResult.AccessToken);
    console.log("accessToken", accessToken)
    const form: any = useRef(null)
    function validateForm() {
        return newPassword.length > 0 && email.length > 0 && oldPassword.length > 0;
    }

    function handleSubmit(event) {
        event.preventDefault();
        let apiUrl = "/api/v1/banyanidp/user/changepassword"
        let data: any = {
            "userName": email,
            "password": newPassword,
            "oldPassword": oldPassword,
            "accessToken": accessToken,
        }

        fetch(apiUrl, {
            method: 'POST', headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(data)
        })
            .then(res => res.json())
            .then(json => {
                if (json.data) {
                    //todo: relogin user?
                    setNewPassword("")
                }
            })
    }

    if (dashboard) {
        return <Redirect to={{
            pathname: '/dashboard',
            data: { user: user }

        }} />
    }
    const styles = {
        card: {
            borderRadius: 10,
        },
        cardHeader: {
            backgroundColor: "#031828",
        },
        cardImage: {
            height: '300px',
            width: '300px',
            align: 'center'
        }
    }

    return (
        <Container>
            <Row className="justify-content-center">
                <Col sm="6">
                    <Card style={styles.card} className="text-center">
                        <Card.Header style={styles.cardHeader}>
                            <Card.Img variant="top" src={logo} style={styles.cardImage} />
                        </Card.Header>

                        <Card.Body>
                            <Row>
                                <Col sm="12">
                                    <Form onSubmit={handleSubmit} ref={form} name="loginform">
                                        <Form.Group as={Row}>
                                            <Form.Label column sm="4">Current Password</Form.Label>
                                            <Col sm="8">
                                                <Form.Control
                                                    type="password"
                                                    name="oldPassword"
                                                    id="oldPassword"
                                                    value={oldPassword}
                                                    onChange={(e) => setOldPassword(e.target.value)}
                                                    placeholder="Enter Current Password"

                                                /></Col>
                                        </Form.Group>
                                        <Form.Group as={Row}>
                                            <Form.Label column sm="4">New Password</Form.Label>
                                            <Col sm="8">
                                                <Form.Control
                                                    type="password"
                                                    name="password"
                                                    id="password"
                                                    value={newPassword}
                                                    onChange={(e) => setNewPassword(e.target.value)}
                                                    placeholder="Enter New Password"

                                                /></Col>
                                        </Form.Group>
                                        <Button block size="lg" type="submit" disabled={!validateForm()}>
                                            Change Password
                                        </Button>
                                    </Form>
                                </Col>
                            </Row>
                            <Row>
                                <Col sm="12">
                                    <br />
                                    <Button block variant="secondary" onClick={() => setDashboard(true)}>Cancel</Button>
                                </Col>
                            </Row>
                        </Card.Body>
                    </Card>
                </Col>
            </Row>
        </Container >
    );
}