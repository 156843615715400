import React, { useState, useRef } from "react";
import Form from "react-bootstrap/Form";
import Button from "react-bootstrap/Button";
import Row from "react-bootstrap/Row"
import Col from "react-bootstrap/Col"
import "./Login.css";
import Container from 'react-bootstrap/Container';
import logo from "./../../logo.png"
import { Redirect } from 'react-router-dom';

export default function ConfirmPassword(props) {
    console.log("from login", props);
    const [confirmPassword, setConfirmPassword] = useState(false)
    const [email, setEmail] = useState(props.location.data.user)
    const [orgName, setOrgName] = useState(props.location.data.orgName)
    const [code, setCode] = useState("");
    const [password, setPassword] = useState("");
    const form: any = useRef(null)
    function validateForm() {
        return email.length > 0 && orgName.length > 0;
    }

    const resendCode = (event) => {
        event.preventDefault();
        let apiUrl = "/api/v1/cognito/user/resendconfirmationcode"
        console.log("user", email);
        let data: any = {
            "userName": email,
            "orgName": orgName,
        }

        fetch(apiUrl, {
            method: 'POST', headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(data)
        })
            .then(res => res.json())
            .then(json => {
                //todo: check for errors
                console.log("user request for new code", json)
            })
    }

    function handleSubmit(event) {
        event.preventDefault();
        let apiUrl = "/api/v1/cognito/user/confirmforgotpassword"
        console.log("user", email);
        let data: any = {
            "userName": email,
            "orgName": orgName,
            "code": code,
            "password": password,
        }

        fetch(apiUrl, {
            method: 'POST', headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(data)
        })
            .then(res => res.json())
            .then(json => {
                //todo: check for errors
                console.log("user to confirm code and new password ...", json)
                setConfirmPassword(true);
                //todo: navigate to dashboard
            })
    }

    if (confirmPassword) {
        return <Redirect to={{
            pathname: '/',
            data: { user: email, orgName: orgName }

        }} />
    }

    return (
        <Container>
            <Row>
                <Col className="banyan-logo" sm="6">
                    <img src={logo}></img>
                </Col>
            </Row>
            <Row>
                <Col sm="6">
                    <Form onSubmit={handleSubmit} ref={form} name="forgotPasswordForm">
                        <Form.Group as={Row}>
                            <Form.Label column sm="3">Org Name</Form.Label>
                            <Col sm="9">
                                <Form.Control
                                    autoFocus
                                    name="orgname"
                                    id="orgname"
                                    value={orgName}
                                    onChange={(e) => setOrgName(e.target.value)}
                                    placeholder="Enter Org Name"
                                /></Col>
                        </Form.Group>
                        <Form.Group as={Row}>
                            <Form.Label column sm="3">Email</Form.Label>
                            <Col sm="9">
                                <Form.Control
                                    autoFocus
                                    name="username"
                                    id="username"
                                    type="email"
                                    value={email}
                                    onChange={(e) => setEmail(e.target.value)}
                                    placeholder="Enter email"
                                /></Col>
                        </Form.Group>
                        <Form.Group as={Row}>
                            <Form.Label column sm="3">Code</Form.Label>
                            <Col sm="9">
                                <Form.Control
                                    autoFocus
                                    name="code"
                                    id="code"
                                    type="text"
                                    value={code}
                                    onChange={(e) => setCode(e.target.value)}
                                    placeholder="Enter Security Code"
                                /></Col>
                        </Form.Group>
                        <Form.Group as={Row}>
                            <Form.Label column sm="3">New Password</Form.Label>
                            <Col sm="9">
                                <Form.Control
                                    type="password"
                                    name="password"
                                    id="password"
                                    value={password}
                                    onChange={(e) => setPassword(e.target.value)}
                                    placeholder="Enter New Password"

                                /></Col>
                        </Form.Group>
                        <Button block size="lg" type="submit" disabled={!validateForm()}>
                            Confirm Password
        </Button>
                    </Form>
                </Col>
            </Row>
            <Row>
                <Col sm="6">
                    <Button onClick={(e) => resendCode(e)}>Resend Code</Button>
                </Col>
            </Row>
        </Container >
    );
}