import React, { useContext, useEffect, useState } from 'react';
import UserContext from './../user/usercontext';
import { Redirect } from 'react-router-dom';
import Card from "react-bootstrap/Card"
import Container from 'react-bootstrap/Container';
import Button from "react-bootstrap/Button";
import Row from "react-bootstrap/Row"
import Col from "react-bootstrap/Col"
import ReactJson from 'react-json-view'


import logo from "./../../logo.png"

export default function Dashboard(props) {
    console.log(props)
    //todo: useContext is not updating the status using reducer. temp fix using props.
    const { Message, SignIn, SignOut } = useContext(UserContext)
    console.log("User Context", Message);
    const [user, setUser] = useState(props.location.data.user)
    const [changePassword, setChangePassword] = useState(false)
    const [setupMFA, setSetupMFA] = useState(false)
    const [accessToken, setAccessToken] = useState<any>(parseJwt(user.Message.AuthenticationResult.AccessToken))
    const [idToken, setIdToken] = useState<any>(parseJwt(user.Message.AuthenticationResult.IdToken))
    useEffect(() => {
        getUserProfile()
    }, []);


    function parseJwt(token) {
        var base64Payload = token.split('.')[1];
        var payload = Buffer.from(base64Payload, 'base64');
        return JSON.parse(payload.toString());
    }



    function getUserProfile() {
        let apiUrl = "/api/v1/cognito/user/profile"
        fetch(apiUrl, {
            method: 'GET', headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + Message.Message.AuthenticationResult.AccessToken
            },
        })
            .then(res => res.json())
            .then(json => {
                if (json.data && json.data.Message) {
                    console.log("user profile", json)
                }
            })
    }

    if (user === undefined || user === null) {
        return <Redirect to={{
            pathname: '/'
        }} />
    }
    if (changePassword) {
        return <Redirect to={{
            pathname: '/changepassword',
            data: { user: user }

        }} />
    }
    if (setupMFA) {
        return <Redirect to={{
            pathname: '/setupmfa',
            data: { user: user }

        }} />
    }

    const styles = {
        card: {
            borderRadius: 10,
        },
        cardHeader: {
            backgroundColor: "#031828",
        },
        cardImage: {
            height: '300px',
            width: '300px',
            align: 'center'
        }
    }

    return (
        <Container>
            <Row className="justify-content-center">
                <Col sm="6">
                    <Card style={styles.card} className="text-center">
                        <Card.Header style={styles.cardHeader}>
                            <Card.Img variant="top" src={logo} style={styles.cardImage} />
                        </Card.Header>

                        <Card.Body>
                            <Row>
                                <Col sm="12">
                                    <Button variant="link" onClick={() => setChangePassword(true)}>Change Password</Button>
                                </Col>
                            </Row>
                            <Row>
                                <Col sm="12">
                                    <Button variant="link" onClick={() => setSetupMFA(true)}>Setup MFA</Button>
                                </Col>
                            </Row>
                            {/* <Row>
                                <Col sm="12">Access Token</Col>
                                <Col sm="12">
                                    <ReactJson src={accessToken} theme="monokai" />
                                </Col>
                            </Row>
                            <Row>
                                <Col sm="12">Id Token</Col>
                                <Col sm="12">
                                    <ReactJson src={idToken} theme="monokai" />
                                </Col>
                            </Row> */}

                        </Card.Body>
                    </Card>
                </Col>
            </Row>
        </Container >
    );
}