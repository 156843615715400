import React, { useState, useRef, useContext } from "react";
import Form from "react-bootstrap/Form";
import Button from "react-bootstrap/Button";
import Row from "react-bootstrap/Row"
import Col from "react-bootstrap/Col"
import Card from "react-bootstrap/Card"
import Image from "react-bootstrap/Image"
import "./Login.css";
import Container from 'react-bootstrap/Container';
import logo from "./../../logo.png"
import { Redirect, useLocation } from 'react-router-dom';
import UserContext, { } from './../user/usercontext';


// A custom hook that builds on useLocation to parse
// the query string for you.
function useQuery() {
    return new URLSearchParams(useLocation().search);
}


export default function Login(props) {
    const { Message, SignIn, SignOut } = useContext(UserContext)
    let query = useQuery();
    console.log("UpdateUserContext", Message)
    const [user, setUser] = useState(null)
    const [newPassword, setNewPassword] = useState(false)
    const [forgotPassword, setForgotPassword] = useState(false)
    const [verifyMFA, setVerifyMFA] = useState(false)
    const [signIn, setSignIn] = useState(false)
    const [signInError, setSignInError] = useState(false)
    const [email, setEmail] = useState("prashanth+test1@banyansecurity.io");
    const [password, setPassword] = useState("");
    const form: any = useRef(null)
    function validateForm() {
        return orgName.length > 0 && email.length > 0 && password.length > 0;
    }


    function handleSubmit(event) {
        event.preventDefault();
        let apiUrl = "/api/v1/banyanidp/user/signin"
        let data: any = {
            "username": email,
            "password": password,
            "orgName": orgName,
            "state": state
        }

        fetch(apiUrl, {
            method: 'POST', headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(data)
        })
            .then(res => {
                console.log("Sign In response: ", res)
                if (res.ok) {
                    return res.json();
                }
                throw Error(res.statusText);
            })
            .then(json => {
                console.log("Sign In Response ", json)
                if (state !== null && state !== undefined && state !== "") {
                    if (json.data && json.data.Message) {
                        console.log("state !== null && state !== undefined && state !== ''", json)
                        window.location.href = json.data.Message;
                        return
                    }
                }
                if (json.data && json.data.ChallengeName === "NEW_PASSWORD_REQUIRED") {
                    setUser(json.data)
                    SignIn({ type: "SIGN_IN", user: json.data })
                    setNewPassword(true)
                    return
                }
                if (json.data && (json.data.ChallengeName === "SMS_MFA" || json.data.ChallengeName === "SOFTWARE_TOKEN_MFA")) {
                    setUser(json.data)
                    SignIn({ type: "SIGN_IN", user: json.data })
                    setVerifyMFA(true)
                }
                else if (json.data) {
                    console.log("redirectUrl", redirectUrl)
                    if (redirectUrl !== null && redirectUrl !== undefined && redirectUrl !== "") {
                        //https://dev04-ft-test2.trust-dev04.bnntest.com/v2/callback
                        console.log("redirect to bnn TP")
                        window.location.href = redirectUrl + "?access_token=" + json.data.AccessToken + "&id_token=" + json.data.IdToken + "&state=" + state;
                    }
                    SignIn({ type: "SIGN_IN", user: json.data })
                    setUser(json.data)
                    setSignIn(true)
                    localStorage.setItem("user", JSON.stringify(json.data));
                } else {
                    console.log("error signing in", json)
                }
            }).catch((error) => {
                // Handle the error
                console.log("error while siging in: ", error);
                setSignInError(true)
            });
    }

    console.log("Query Params redirect_uri:", query.get("redirect_uri"));
    console.log("Query Params state:", query.get("state"));
    console.log("Query Params state:", query.get("org_name"));

    const [state, setState] = useState(query.get("state"));
    const [redirectUrl, setRedirectUrl] = useState(query.get("redirect_uri"));
    const [orgName, setOrgName] = useState(query.get("org_name") ?? "dev04-ft-test1");

    //setOrgName(query.get("org_name") ?? "dev04-ft-test1")
    if (newPassword) {
        return <Redirect to={{
            pathname: '/newpassword',
            data: { user: user, orgName: orgName, state: state }

        }} />
    }
    if (signInError) {
        return <Redirect to={{
            pathname: '/signinerror',
            data: { user: user, orgName: orgName, state: state }

        }} />
    }

    if (verifyMFA) {
        return <Redirect to={{
            pathname: '/verifymfa',
            data: { user: user, orgName: orgName, state: state }

        }} />
    }
    if (forgotPassword) {
        return <Redirect to={{
            pathname: '/forgotpassword',
            data: { user: email, orgName: orgName, state: state }
        }} />
    }
    if (signIn) {
        return <Redirect to={{
            pathname: '/dashboard',
            data: { user: user, orgName: orgName }

        }} />
    }


    const styles = {
        card: {
            borderRadius: 10,
        },
        cardHeader: {
            backgroundColor: "#031828",
        },
        cardImage: {
            height: '300px',
            width: '300px',
            align: 'center'
        }
    }

    return (
        <Container>
            <Row className="justify-content-center">
                <Col sm="6">
                    <Card style={styles.card} className="text-center">
                        <Card.Header style={styles.cardHeader}>
                            <Card.Img variant="top" src={logo} style={styles.cardImage} />
                        </Card.Header>

                        <Card.Body>
                            <Row>
                                <Col sm="12">
                                    <Form onSubmit={handleSubmit} ref={form} name="loginform">
                                        <Form.Group as={Row}>
                                            <Form.Label column sm="3">Org Name</Form.Label>
                                            <Col sm="9">
                                                <Form.Control
                                                    autoFocus
                                                    name="orgname"
                                                    id="orgname"
                                                    value={orgName}
                                                    onChange={(e) => setOrgName(e.target.value)}
                                                    placeholder="Enter Org Name"
                                                /></Col>
                                        </Form.Group>
                                        <Form.Group as={Row}>
                                            <Form.Label column sm="3">Email</Form.Label>
                                            <Col sm="9">
                                                <Form.Control
                                                    autoFocus
                                                    name="username"
                                                    id="username"
                                                    type="email"
                                                    value={email}
                                                    onChange={(e) => setEmail(e.target.value)}
                                                    placeholder="Enter email"
                                                /></Col>
                                        </Form.Group>
                                        <Form.Group as={Row}>
                                            <Form.Label column sm="3">Password</Form.Label>
                                            <Col sm="9">
                                                <Form.Control
                                                    type="password"
                                                    name="password"
                                                    id="password"
                                                    value={password}
                                                    onChange={(e) => setPassword(e.target.value)}
                                                    placeholder="Enter Password"

                                                /></Col>
                                        </Form.Group>
                                        <Button block size="lg" type="submit" disabled={!validateForm()}>
                                            Login
        </Button>
                                    </Form>
                                </Col>
                            </Row>
                            <br></br>
                            <Row>
                                <Col sm="12">
                                    <Button variant="secondary" block onClick={() => setForgotPassword(true)}>Forgot Password</Button>
                                </Col>
                            </Row>
                        </Card.Body>
                    </Card>
                </Col>
            </Row>
        </Container >
    );
}